<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12" class="mb-2">
        <span class="font-weight-medium text-body-1 primary--text text--darken-1">{{ $t('news.newsList') }}</span>
        <v-btn
          v-if="!(newsList.data && newsList.data.length === 1) && checkAccessRight('company-c-eate')"
          :class="locale === 'he' ? 'float-left' : 'float-right'"
          outlined
          small
          color="primary"
          :to="{path: 'news/addNews'}"
        >
          <v-icon left>
            mdi-plus
          </v-icon>
          {{ $t('news.news') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card
          class="mx-auto"
          outlined
        >
          <ListSkeleton v-if="listSkeleton"/>
          <v-card-text class="text-h5 pa-0" v-else-if="newsList.data">
            <v-data-table
              :dense="true"
              class="custom-table mb-0 ma-2 row-pointer"
              :headers="headers"
              :items="newsList.data"
              :items-per-page="limit"
              :page.sync="page"
              :server-items-length="newsList.meta.total"
              :options.sync="pagination"
              item-class="py-4"
              :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50]
              }"
              :search="search"
              @dblclick:row="redirectOnEditPage"
              @update:sort-by="(val) => val && buildUrl(val, 'sortKey')"
            >
              <!-- <template
                v-slot:top
              >
                <div class="text-body-2 primary--text mb-2">
                  <v-row :dense="true">
                    <v-col cols="8">
                    </v-col>
                    <v-col cols="4" class="text-end d-flex">
                      <v-text-field
                        :full-width="false"
                        :dense="true"
                        hide-details="auto"
                        v-model="search"
                        @input="buildUrl"
                        append-icon="mdi-magnify"
                        :label="$t('common.search')"
                        single-line
                      />
                    </v-col>
                  </v-row>
                </div>
                <v-divider />
              </template> -->
              <template v-slot:[`item.actions`]="props">
                <v-menu
                  offset-x
                  left
                  content-class="elevation-2"
                  flat
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item dense class="primary--text" :to="'news/editNews/' + props.item.id" v-if="checkAccessRight('company-edit')">
                      <v-list-item-content>
                        <v-list-item-title>{{$t('common.view')}}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon small class="primary--text">mdi-eye-outline</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                    <v-list-item dense @click="confirmationDialog = true, delete_item = props.item" v-if="checkAccessRight('company-d-lete')">
                      <v-list-item-content class="error--text">
                        <v-list-item-title>{{$t('common.delete')}}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon small class="error--text">mdi-delete-sweep-outline</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-slot:[`footer.prepend`]>
                <v-row class="d-flex">
                  <v-col class="mx-auto" cols="12">
                    <span class="pl-3 pt-2">{{ $t('common.numberOfTotalRows') }} {{newsList.meta.total}}</span>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:[`footer.page-text`]>
                <v-row class="d-flex">
                  <v-col class="mx-auto mr-16" cols="4">
                    <v-container class="w-100">
                      <v-pagination
                        :total-visible="5"
                        v-model="page"
                        @input="buildUrl"
                        :length="newsList.meta.last_page"
                      ></v-pagination>
                    </v-container>
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-text class="text-caption text-center" v-else> {{$t('common.noDataFound')}}</v-card-text>
        </v-card>
      </v-col>
      <confirmation-dialog :dialog.sync="confirmationDialog" :delete_item.sync="delete_item" @deleteItem="deleteItem"/>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import ListSkeleton from '@/components/skeleton/ListSkeleton';
import ConfirmationDialog from '@/components/ConfirmationDialog';
import {formatDateDDMMYYYY, parseDateYYYYMMDD} from '@/utils';

export default {
  name: 'News',
  components: {
    ListSkeleton,
    ConfirmationDialog
  },
  data() {
    return {
      page: Number(this.$route.query.page) || 1,
      limit: 10,
      listSkeleton: false,
      confirmationDialog: false,
      delete_item: null,
      accessRights: [],
      domain: process.env.VUE_APP_BASE_DOMAIN,
      search: this.$route.query.search || "",
      pagination: {},
    }
  },
  watch: {
    params: {
      handler(newVal, oldVal) {
        if(JSON.stringify(newVal)!==JSON.stringify(oldVal) || !Object.keys(this.$route.query).length)
          this.paginate();
      },
      deep: true
    },
    '$route.query': {
      handler(newVal,oldVal) {
        if(JSON.stringify(newVal)!==JSON.stringify(oldVal) || !Object.keys(this.$route.query).length)
          this.page = Number(this.$route.query.page) || 1
          
          this.search = this.$route.query.search || ""
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.search
      };
    },
    ...mapGetters([
        'locale',
        'user',
        'newsList',
    ]),
    headers() {
      return [
        { text: this.$t('news.description'), value: "original_description", sortable: true, width:"1200px" },
        { text: this.$t('news.newsDate'), value: "news_date", sortable: true },
        { text: this.$t('news.action'), value: "actions", align: "center", sortable: false },
      ]
    }
  },
  async mounted() {
    this.listSkeleton = true;
    await this.$store.dispatch('GetNews', {page: this.page, limit: this.limit});
    this.listSkeleton = false;
    if(this.user.role) {
      this.accessRights = this.user.role.permissions;
    }
  },
  methods: {
    parseDateYYYYMMDD:parseDateYYYYMMDD,
    formatDateDDMMYYYY: formatDateDDMMYYYY,
    async paginate() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.params;
      await this.$store.dispatch('GetNews', {page: page, limit: itemsPerPage, where_like: this.search ? sortBy[0] ? sortBy[0]+'|'+this.search : sortBy[1] ? sortBy[1]+'|'+this.search : 'original_description|'+ this.search : 'original_description|', order_by: sortBy?.[0] ? sortBy?.[0]+'|'+(sortDesc?.[0] ? 'desc' : 'asc') : 'original_description|'+(sortDesc?.[0] ? 'desc' : 'asc')});
    },
     buildUrl(val, url_key) {
      let obj = {};
      if (typeof val === 'string' && url_key === 'sortKey' && !this.$route.query.page) {
        return
      }
      if (typeof val === 'string') {
        this.page = 1;
        obj.page = 1;
        this.params.page = 1;
      }
      obj.search = this.search;
      obj.page = this.page;
      obj = Object.entries(obj).reduce((acc, [key, val]) => {
        if (key === "groupId" && val === null) return acc;
        else if (key === "page" && val === 1) return acc;
        else if (!val) return acc;
        return { ...acc, [key]: val };
      }, {});
      this.$router.push({
        name: "News",
        query: {
          ...obj,
        },
      });
    },
    redirectOnEditPage(event, {item}) {
      this.$router.push('news/editNews/' + item.id)
    },
    checkAccessRight(checkName) {
      if(this.accessRights.length > 0) {
        return this.accessRights.some((role) => role.slug === checkName);
      } else {
        return true;
      } 
    },
    async deleteItem(item) {
      await this.$store.dispatch('DeleteNewsItemById', item);
      this.search = this.$route.query.search || "";
      this.page = Number(this.$route.query.page) || 1;
      this.limit = 10;
      this.paginate();
    }
  }
}
</script>
