<template>
  <div>
    <div v-if="this.$route.name === 'News'">
      <News />
    </div>
    <router-view />
  </div>
</template>
<script>
import News from "@/views/adminApp/news/News.vue";

export default {
  name: 'NewsParent',
  components: {
    News
  }
}
</script>
